import React from 'react';

function Icon() {
	return (
		<svg
			viewBox="0 0 90.403 140.746"
			style={{ maxHeight: '2rem' }}
			aria-hidden="true"
		>
			<ellipse
				cx="45.202"
				cy="45.429"
				fill="#af263d"
				paintOrder="markers fill stroke"
				rx="45.202"
				ry="44.596"
			></ellipse>
			<path
				fill="#f9f9f9"
				d="M23.824 61.358c3.92-.104 4.489-.164 6.233-.66 1.315-.373 1.496-.455 2.119-.962.348-.284.679-.516.734-.516.055 0 .136-.12.18-.267.045-.147.202-.4.35-.564.36-.399.843-1.657 1.016-2.652.444-2.553-.953-4.404-3.653-4.84-.475-.077-.865-.18-.865-.228 0-.048.247-.15.55-.226 1.915-.483 3.19-1.937 3.476-3.966.353-2.504-.866-4.032-3.68-4.613-.913-.188-12.184-.156-12.415.036-.075.062-.172.552-.216 1.088a2375.154 2375.154 0 01-1.547 15.966c-.098.93-.178 1.879-.178 2.107v.415l1.989-.007c1.094-.004 3.752-.054 5.907-.111z"
			></path>
			<path
				fill="#af263d"
				d="M21.626 58.55c-.005-.569.517-5.747.6-5.95.107-.262.141-.266 2.242-.266 2.966 0 3.708.201 4.38 1.19.258.379.277.51.238 1.602-.037 1.035-.084 1.26-.364 1.738-.416.71-.873 1.087-1.72 1.418-.607.238-.95.274-3.03.319-1.29.027-2.345.005-2.346-.051zm.834-9.026c0-.055.107-1.218.237-2.585.154-1.621.287-2.522.384-2.59.082-.056 1.038-.083 2.126-.059 1.815.04 2.022.068 2.538.333.746.384 1.006.875 1.005 1.89 0 .565-.077.935-.281 1.368-.34.72-.663 1.031-1.441 1.389-.527.242-.81.276-2.581.312-1.094.023-1.988-.004-1.988-.058z"
			></path>
			<path
				fill="#f9f9f9"
				d="M50.301 61.062c2.847-.348 4.715-.632 4.814-.733.101-.102.25-1.857.773-9.183.485-6.772.485-6.765.219-6.78-.96-.056-4.884.058-4.99.145-.073.06-.167.469-.209.908-.097 1.02-.606 7.628-.8 10.395-.135 1.93-.17 2.128-.393 2.254-.16.09-.95.139-2.264.14-2.304.003-2.876-.133-3.49-.833-.726-.826-.741-1.28-.25-7.396.394-4.902.412-5.315.232-5.426-.068-.042-1.244-.038-2.614.01-2.402.083-2.491.094-2.547.336-.063.272-.712 7.807-.84 9.75-.263 4.021 1.412 5.998 5.588 6.593 1.027.146 4.961.042 6.771-.18zm15.139.06c4.81-.314 7.564-2.062 8.08-5.131.313-1.861-.526-3.321-2.468-4.29-.442-.222-1.579-.658-2.526-.97-2.867-.946-3.562-1.402-3.562-2.343 0-.982.707-1.545 2.222-1.772.488-.073 1.82-.09 3.38-.044 2.97.088 2.666.216 3.014-1.275.14-.603.175-.962.098-1.038-.2-.201-2.515-.478-4.617-.552-4.83-.17-8.059 1.003-9.214 3.35-.508 1.032-.59 2.094-.234 3.036.508 1.346 1.801 2.213 4.936 3.31 2.776.97 3.5 1.605 3.385 2.968-.043.514-.395.95-1.01 1.25-.978.477-1.676.549-4.952.509-2-.025-3.093.005-3.174.085-.068.068-.138.681-.155 1.362l-.03 1.239.652.098c.664.1 2.945.278 3.918.305.294.008 1.31-.036 2.256-.097z"
			></path>
			<path
				fill="#f9f9f9"
				fillOpacity="0.941"
				d="M45.34 0A46.23 45.012 0 001.628 30.504h87.415A46.23 45.012 0 0045.34 0zm-7.975 89.305a46.23 45.012 0 007.975.72 46.23 45.012 0 008.153-.72z"
				paintOrder="markers fill stroke"
			></path>
			<path
				fill="#af263d"
				d="M36.127 105.131h18.484v35.615H36.127z"
				paintOrder="markers fill stroke"
			></path>
			<path
				fill="#af263d"
				d="M31.515 77.247h26.944v32.222H31.515z"
				paintOrder="markers fill stroke"
			></path>
		</svg>
	);
}

export default Icon;
